import { Classes, Icon, IconName } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  icon?: IconName;
  minimal?: boolean;
  text?: string;
  to: Parameters<Link>[0]['to'];
}

export function LinkButton({ icon, minimal, text, to }: IProps) {
  return (
    <Link
      className={cx(Classes.BUTTON, { [Classes.MINIMAL]: minimal })}
      to={to}
    >
      {icon && <Icon icon={icon} />}
      {text && <span className={Classes.BUTTON_TEXT}>{text}</span>}
    </Link>
  );
}
