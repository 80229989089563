import { BadRequestError, ValidationError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  Button,
  Classes,
  FormGroup,
  H2,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { resetPassword } from 'accounts/api';
import * as React from 'react';

import { FormPageWrapper } from './formPageWrapper';

export function ResetPasswordPage() {
  const api = useApiClient();
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState<string>();
  const [isEmailSent, setIsEmailSent] = React.useState(false);

  return (
    <FormPageWrapper>
      <H2>Получение доступа</H2>

      {isEmailSent ? (
        <div className={Classes.RUNNING_TEXT}>
          <p>
            На адрес <strong>{email}</strong> отправлено письмо со ссылкой для
            установки пароля
          </p>
        </div>
      ) : (
        <BaseForm
          onSubmit={async event => {
            try {
              setFormError(undefined);
              setEmailError(undefined);
              setIsSubmitting(true);
              event.preventDefault();
              await resetPassword(api, email);
              setIsEmailSent(true);
            } catch (err) {
              if (err instanceof ValidationError) {
                if (err.formError.length !== 0) {
                  setFormError(err.formError.join('\n'));
                }

                const fieldErrors = err.joinFieldErrors();

                if (fieldErrors.email) {
                  setEmailError(fieldErrors.email as string);
                }
              } else if (err instanceof BadRequestError) {
                setFormError(err.message);
              } else {
                setFormError(
                  'Не удалось получить доступ: Непредвиденная ошибка'
                );
                throw err;
              }
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          <FormErrors error={formError} />

          <FormGroup
            helperText={emailError}
            intent={emailError ? Intent.DANGER : undefined}
            label="Email"
            labelFor="email"
          >
            <InputGroup
              autoComplete="username"
              disabled={isSubmitting}
              id="email"
              intent={emailError ? Intent.DANGER : undefined}
              large
              placeholder="user@example.com"
              type="email"
              value={email}
              onChange={event => {
                setEmail(event.currentTarget.value);
              }}
            />
          </FormGroup>

          <Button
            disabled={isSubmitting}
            fill
            intent={Intent.PRIMARY}
            large
            loading={isSubmitting}
            type="submit"
          >
            Получить доступ
          </Button>
        </BaseForm>
      )}
    </FormPageWrapper>
  );
}
