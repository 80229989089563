import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CenteredWrapper } from '_core/layout/centeredWrapper';
import {
  Alignment,
  Button,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Spinner,
} from '@blueprintjs/core';
import { ErrorBoundary } from '@sentry/react';
import { formatUserName } from 'accounts/formatUserName';
import * as React from 'react';

import { useAuth, useProfile } from './authContext';
import * as css from './layout.module.css';
import { LinkButton } from './linkButton';

interface Props {
  children: React.ReactNode;
  orgName?: string;
}

export function Layout({ children, orgName }: Props) {
  const auth = useAuth();
  const { error, isFetched, profile } = useProfile();

  return (
    <div className={css.root}>
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        {!isFetched ? (
          <CenteredWrapper>
            <Spinner />
          </CenteredWrapper>
        ) : error ? (
          <GenericErrorMessage error={error} />
        ) : (
          <>
            <Navbar>
              <NavbarGroup>
                <NavbarHeading>
                  <LinkButton icon="home" minimal text={orgName} to="/" />
                </NavbarHeading>
              </NavbarGroup>

              <NavbarGroup align={Alignment.RIGHT}>
                {profile ? (
                  <>
                    <span>{formatUserName(profile)}</span>

                    <Button
                      icon="log-out"
                      minimal
                      onClick={() => {
                        auth.logout();
                      }}
                    />
                  </>
                ) : (
                  <LinkButton icon="log-in" minimal text="Войти" to="/login" />
                )}
              </NavbarGroup>
            </Navbar>

            <div className={css.children}>{children}</div>
          </>
        )}
      </ErrorBoundary>
    </div>
  );
}
