import '_core/init/sentry-browser';
import '_core/init/dayjs';
import './browser.css';

import { createApiClient } from '_core/api/client';
import { ORG_NAME } from '_core/config';
import { FocusStyleManager } from '@blueprintjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './app';

FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
  <React.StrictMode>
    <App api={createApiClient(fetch)} orgName={ORG_NAME} />
  </React.StrictMode>,
  document.getElementById('app')
);
