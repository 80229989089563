import { ApiClient } from '_core/api/client';
import { ApiClientProvider } from '_core/api/context';
import { CenteredWrapper } from '_core/layout/centeredWrapper';
import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { HeadProvider, Title } from 'react-head';
import { BrowserRouter, Redirect, RouteProps } from 'react-router-dom';
import { Link, Route, Switch } from 'react-router-dom';

import { AuthProvider, useProfile } from './authContext';
import { HomePage } from './home';
import { Layout } from './layout';
import { LoginPage } from './login';
import { ResetPasswordPage } from './resetPassword';
import { SetPasswordPage } from './setPassword';

function PublicOnlyRoute({ children, ...otherProps }: RouteProps) {
  const { profile } = useProfile();

  return (
    <Route {...otherProps}>{profile ? <Redirect to="/" /> : children}</Route>
  );
}

function PrivateRoute({ children, ...otherProps }: RouteProps) {
  const { profile } = useProfile();

  return (
    <Route {...otherProps}>
      {profile ? children : <Redirect to="/login" />}
    </Route>
  );
}

interface Props {
  api: ApiClient;
  orgName?: string;
}

export function App({ api, orgName }: Props) {
  return (
    <BrowserRouter>
      <HeadProvider>
        <Title>{orgName}</Title>

        <ApiClientProvider api={api}>
          <AuthProvider>
            <Layout orgName={orgName}>
              <Switch>
                <PrivateRoute exact path="/">
                  <HomePage />
                </PrivateRoute>

                <PublicOnlyRoute path="/login">
                  <LoginPage />
                </PublicOnlyRoute>

                <Route path="/reset-password">
                  <ResetPasswordPage />
                </Route>

                <Route path="/set-password">
                  <SetPasswordPage />
                </Route>

                <Route>
                  <CenteredWrapper>
                    <NonIdealState icon="error" title="404">
                      Страница не существует, попробуйте начать с{' '}
                      <Link to="/">Главной страницы</Link>.
                    </NonIdealState>
                  </CenteredWrapper>
                </Route>
              </Switch>
            </Layout>
          </AuthProvider>
        </ApiClientProvider>
      </HeadProvider>
    </BrowserRouter>
  );
}
