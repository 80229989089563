import { Spinner } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './spinnerOverlay.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  show: boolean;
}

export const SpinnerOverlay = React.forwardRef(function Overlay(
  { children, className, show, ...otherProps }: Props,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div {...otherProps} className={cx(className, css.root)} ref={ref}>
      {show && (
        <div className={css.overlay}>
          <div className={css.spinner}>
            <Spinner />
          </div>
        </div>
      )}
      {children}
    </div>
  );
});
