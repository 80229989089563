import { Card } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './formPageWrapper.module.css';

interface Props {
  children: React.ReactNode;
}

export function FormPageWrapper({ children }: Props) {
  return (
    <div className={css.root}>
      <Card className={css.card} elevation={3}>
        {children}
      </Card>
    </div>
  );
}
