import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { serializable } from '_core/serializable';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import { IParkSchedule } from 'parksSchedules/api';

const BASE_URL = '/customer/parks';

interface CustomerParkSerialized {
  currentUserLastMailed: string | null;
  dailySchedules: IParkSchedule[];
  id: number;
  latestDislocationChange: string;
  name: string;
  wagonCount: number;
  weekendSchedules: IParkSchedule[];
}

interface ICustomerPark
  extends Omit<
    CustomerParkSerialized,
    'currentUserLastMailed' | 'latestDislocationChange'
  > {
  currentUserLastMailed: Date | null;
  latestDislocationChange: Date;
}

@serializable
export class CustomerPark implements ICustomerPark {
  @serializable.datetime readonly currentUserLastMailed;
  readonly dailySchedules;
  readonly id;
  @serializable.datetime readonly latestDislocationChange;
  readonly name;
  readonly wagonCount;
  readonly weekendSchedules;

  static fromJSON(serialized: CustomerParkSerialized) {
    return serializable.fromJSON(CustomerPark, serialized);
  }

  constructor(customerPark: ICustomerPark) {
    this.currentUserLastMailed = customerPark.currentUserLastMailed;
    this.dailySchedules = customerPark.dailySchedules;
    this.id = customerPark.id;
    this.latestDislocationChange = customerPark.latestDislocationChange;
    this.name = customerPark.name;
    this.wagonCount = customerPark.wagonCount;
    this.weekendSchedules = customerPark.weekendSchedules;
  }
}

export enum CustomerParksSortingField {
  CurrentUserLastMailed = 'currentUserLastMailed',
  LatestDislocationChange = 'latestDislocationChange',
  Name = 'name',
  WagonCount = 'wagonCount',
}

export async function fetchCustomerParks(
  api: ApiClient,
  { sorting }: { sorting?: Array<ISorting<CustomerParksSortingField>> } = {}
): Promise<ListResponse<CustomerPark>> {
  const response = await api.get<ListResponse<CustomerParkSerialized>>(
    BASE_URL,
    { ordering: sorting && sortingToApiQueryParam(sorting, snakeCase) }
  );

  return {
    ...response,
    results: response.results.map(CustomerPark.fromJSON),
  };
}

export function getCustomerParksDownloadHref(api: ApiClient, parkId: number) {
  return api.buildApiUrl(`${BASE_URL}/${parkId}/download_xlsx`);
}
