import { ValidationError } from '_core/api/client';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { InputPassword } from '_core/inputs/inputPassword';
import { Button, FormGroup, H2, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from './authContext';
import { FormPageWrapper } from './formPageWrapper';

export function SetPasswordPage() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState<string>();

  const searchParams = new URLSearchParams(location.search);

  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  return !uid || !token ? (
    <Redirect to="/reset-password" />
  ) : (
    <FormPageWrapper>
      <H2>Получение доступа</H2>

      <BaseForm
        onSubmit={async event => {
          try {
            setFormError(undefined);
            setPasswordError(undefined);
            setIsSubmitting(true);
            event.preventDefault();
            await auth.resetPasswordConfirm(uid, token, password);
            history.replace('/');
          } catch (err) {
            setIsSubmitting(false);

            if (err instanceof ValidationError) {
              if (err.formError.length !== 0) {
                setFormError(err.formError.join('\n'));
              }

              const fieldErrors = err.joinFieldErrors();

              if (fieldErrors.password) {
                setPasswordError(fieldErrors.password as string);
              }
            } else {
              setFormError('Не удалось задать пароль: Непредвиденная ошибка');
              throw err;
            }
          }
        }}
      >
        <FormErrors error={formError} />

        <FormGroup
          helperText={passwordError}
          intent={passwordError ? Intent.DANGER : undefined}
          label="Пароль"
          labelFor="password"
        >
          <InputPassword
            autoComplete="new-password"
            disabled={isSubmitting}
            id="password"
            intent={passwordError ? Intent.DANGER : undefined}
            large
            placeholder="**********"
            value={password}
            onChange={event => {
              setPassword(event.currentTarget.value);
            }}
          />
        </FormGroup>

        <Button
          disabled={isSubmitting}
          fill
          intent={Intent.PRIMARY}
          large
          loading={isSubmitting}
          type="submit"
        >
          Установить пароль
        </Button>
      </BaseForm>
    </FormPageWrapper>
  );
}
