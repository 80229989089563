import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CenteredWrapper } from '_core/layout/centeredWrapper';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { expandSorting, ISorting, SortingDirection } from '_core/sorting';
import { SortingInput } from '_core/sortingInput';
import { SpinnerOverlay } from '_core/spinnerOverlay';
import { useAsyncData } from '_core/useAsyncData';
import { useMatchMedia } from '_core/useMatchMedia';
import {
  AnchorButton,
  Card,
  FormGroup,
  H5,
  HTMLTable,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import {
  CustomerPark,
  CustomerParksSortingField,
  fetchCustomerParks,
  getCustomerParksDownloadHref,
} from 'customers/parks/api';
import dayjs from 'dayjs';
import { Row, VGrid } from 'layout/contentLayout';
import { formatParkSchedules } from 'parksSchedules/api';
import * as React from 'react';

import * as css from './home.module.css';

enum SortingField {
  CurrentUserLastMailed = 'currentUserLastMailed',
  LatestDislocationChange = 'latestDislocationChange',
  Name = 'name',
  WagonCount = 'wagonCount',
}

const defaultSorting: ISorting<SortingField> = {
  field: SortingField.LatestDislocationChange,
  direction: SortingDirection.Descending,
};

const columnLabels = {
  dailySchedules: 'Будние дни',
  [SortingField.CurrentUserLastMailed]: 'Последняя рассылка',
  [SortingField.LatestDislocationChange]: 'Дислокация обновлена',
  [SortingField.WagonCount]: 'Количество вагонов',
  weekendSchedules: 'Выходные дни',
};

export function HomePage() {
  const api = useApiClient();

  const [sorting, setSorting] =
    React.useState<ISorting<SortingField>>(defaultSorting);

  const { data, error, isFetching } = useAsyncData([api, sorting], () =>
    fetchCustomerParks(api, {
      sorting: expandSorting(sorting, {
        [SortingField.CurrentUserLastMailed]: [
          CustomerParksSortingField.CurrentUserLastMailed,
        ],
        [SortingField.LatestDislocationChange]: [
          CustomerParksSortingField.LatestDislocationChange,
        ],
        [SortingField.Name]: [CustomerParksSortingField.Name],
        [SortingField.WagonCount]: [CustomerParksSortingField.WagonCount],
      }),
    })
  );

  const isMobile = useMatchMedia('(max-width: 768px)');

  const columns = React.useMemo(
    (): Array<ListTableColumn<CustomerPark>> => [
      {
        id: SortingField.Name,
        label: 'Наименование',
        defaultWidth: 400,
        sortable: true,
        copyCellContent: park => park.name,
        renderCellContent: park => park.name,
      },
      {
        id: SortingField.WagonCount,
        label: columnLabels.wagonCount,
        defaultWidth: 160,
        sortable: true,
        copyCellContent: park => String(park.wagonCount),
        renderCellContent: park => park.wagonCount,
      },
      {
        id: 'dailySchedules',
        label: columnLabels.dailySchedules,
        defaultWidth: 300,
        copyCellContent: park => formatParkSchedules(park.dailySchedules),
        renderCellContent: park => formatParkSchedules(park.dailySchedules),
      },
      {
        id: 'weekendSchedules',
        label: columnLabels.weekendSchedules,
        defaultWidth: 300,
        copyCellContent: park => formatParkSchedules(park.weekendSchedules),
        renderCellContent: park => formatParkSchedules(park.weekendSchedules),
      },
      {
        id: SortingField.CurrentUserLastMailed,
        label: columnLabels[SortingField.CurrentUserLastMailed],
        defaultWidth: 180,
        sortable: true,
        copyCellContent: park =>
          park.currentUserLastMailed
            ? dayjs(park.currentUserLastMailed).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: park =>
          park.currentUserLastMailed &&
          dayjs(park.currentUserLastMailed).format(DATE_FORMAT_DATETIME),
      },
      {
        id: SortingField.LatestDislocationChange,
        label: columnLabels[SortingField.LatestDislocationChange],
        defaultWidth: 200,
        sortable: true,
        copyCellContent: park =>
          dayjs(park.latestDislocationChange).format(DATE_FORMAT_DATETIME),
        renderCellContent: park =>
          dayjs(park.latestDislocationChange).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'download',
        label: 'Актуальная дислокация',
        defaultWidth: 180,
        copyCellContent: park => getCustomerParksDownloadHref(api, park.id),
        renderCellContent: park => (
          <AnchorButton
            href={getCustomerParksDownloadHref(api, park.id)}
            intent={Intent.SUCCESS}
            minimal
            text="Скачать .xlsx"
          />
        ),
      },
    ],
    [api]
  );

  const getItemId = React.useCallback(
    (park: CustomerPark) => String(park.id),
    []
  );

  return !data ? (
    <CenteredWrapper>
      {isFetching ? <Spinner /> : <GenericErrorMessage error={error} />}
    </CenteredWrapper>
  ) : data.results.length === 0 ? (
    <CenteredWrapper>
      <NonIdealState
        description="Нам не удалось найти парки для вас"
        icon="list"
        title="Нет парков"
      />
    </CenteredWrapper>
  ) : isMobile === undefined ? null : isMobile ? (
    <>
      <FormGroup>
        <SortingInput
          defaultValue={defaultSorting}
          fill
          options={columns
            .filter(column => column.sortable)
            .map(column => ({
              label: column.label,
              value: column.id as SortingField,
            }))}
          value={sorting}
          onChange={setSorting}
        />
      </FormGroup>

      <SpinnerOverlay show={isFetching}>
        <VGrid>
          {data.results.map(park => (
            <Row key={park.id}>
              <Card elevation={1}>
                <H5 className={css.cardHeaderTitle}>
                  <AnchorButton
                    className={css.cardHeaderButton}
                    href={getCustomerParksDownloadHref(api, park.id)}
                    intent={Intent.SUCCESS}
                    text="Скачать .xlsx"
                  />

                  {park.name}
                </H5>

                <HTMLTable className={css.cardTable} striped>
                  <tbody>
                    <tr>
                      <td>{columnLabels[SortingField.WagonCount]}</td>
                      <td>{park.wagonCount}</td>
                    </tr>

                    <tr>
                      <td>{columnLabels.dailySchedules}</td>
                      <td>{formatParkSchedules(park.dailySchedules)}</td>
                    </tr>

                    <tr>
                      <td>{columnLabels.weekendSchedules}</td>
                      <td>{formatParkSchedules(park.weekendSchedules)}</td>
                    </tr>

                    <tr>
                      <td>
                        {columnLabels[SortingField.CurrentUserLastMailed]}
                      </td>
                      <td>
                        {park.currentUserLastMailed &&
                          dayjs(park.currentUserLastMailed).format(
                            DATE_FORMAT_DATETIME
                          )}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {columnLabels[SortingField.LatestDislocationChange]}
                      </td>
                      <td>
                        {dayjs(park.latestDislocationChange).format(
                          DATE_FORMAT_DATETIME
                        )}
                      </td>
                    </tr>
                  </tbody>
                </HTMLTable>
              </Card>
            </Row>
          ))}
        </VGrid>
      </SpinnerOverlay>
    </>
  ) : (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={data.results}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
}
